.lazy-loading-video-group {
  position: relative;

  &--top {
    padding-top: 91.8%
  }

  &--bottom {
    padding-top: 91.8%;
  }
}

.lazy-loading-top-video {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  //max-width: 400px;
}

.lazy-loading-bottom-video {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100% !important;
}

@media (min-width: 768px) {
  .lazy-loading-video-group--bottom {
    padding-top: 32.8%;
  }

  .lazy-loading-bottom-video {
    max-width: 250px !important;
  }
}

@media (min-width: 992px) {
  .lazy-loading-video-group--bottom {
    padding-top: 91.8%;
  }

  .lazy-loading-top-video {
    left: 0;
    transform: translateX(0);
  }

  .lazy-loading-bottom-video {
    left: 0;
    transform: translateX(0);
    max-width: 450px !important;
  }
}
