.btn {
  font-size: 14px;
  font-weight: 500;
}

.btn-primary {
  background-color: #91c524 !important;
  border-color: #91c524 !important;
  color: $color-white !important;
  line-height: 36px;
}

.btn-blue {
  background: #5AA0FF;
  box-shadow: 0 5px 20px #D8DDCD;
  color: $color-white !important;
  line-height: 36px;
}

.btn-orange {
  background-color: map-get($color-general, orange) !important;
  border-color: map-get($color-general, orange) !important;
  color: $color-white !important;
  line-height: 36px;
}

.btn.shadow {
  box-shadow: 0 5px 20px 0 #d8ddcd;
}

.btn.w250 {
  width: 250px;
}
