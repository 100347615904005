.s-hp-feature2 {
  position: relative;
}

.s-hp-feature2 .c1 {
  position: relative;
  height: 484px;
  background-color: map-get($color-general, blue);
  color: $color-white;
  margin-top: -100px;
  overflow: hidden;

  &:before {
    content: '';

    display: block;
    width: 100%;
    height: 100%;

    position: absolute;
    right: 0;
    left: 0;
    transform: translateX(0);

    pointer-events: none;

    background-color: map-get($color-general, orange);
  }
}

@media (min-width: 768px) {
  .s-hp-feature2 .c1 {
    margin-top: -115px;
  }
}

@media (min-width: 1100px) {
  .s-hp-feature2 .c1 {
    min-width: 893px;
    width: 60%;
  }
}

@media (min-width: 1200px) {
  .s-hp-feature2 .c1 {
    margin-top: -130px;
  }
}

.s-hp-feature2 .c1 h2 {
  position: absolute;
  top: 60px;
  text-align: center;
  left: 0;
  right: 0;

  opacity: 0;
}

@media (min-width: 1100px) {
  .s-hp-feature2 .c1 h2 {
    position: absolute;
    top: 60px;
    left: auto;
    right: 80px;
    width: 600px;
  }
}

.s-hp-feature2 .c1 img {
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -9px;
  top: 250px;

  opacity: 0;
}

@media (min-width: 1100px) {
  .s-hp-feature2 .c1 img {
    display: none;
  }
}

@media (min-width: 1300px) {
  .s-hp-feature2 .c1 img {
    display: block;
    right: 662px;
    left: auto;
    margin-left: 0;
  }
}

.s-hp-feature2 .c2 {
  position: relative;
  margin-top: -270px;
  background-color: $color-white;
  box-shadow: 0 10px 50px 0 rgba(4, 13, 35, 0.09);
  margin-bottom: 80px;

  .row {
    background-color: $color-white;

    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.5);

    &:after {
      content: '';

      display: none;
      width: 400px;
      height: 400px;

      position: absolute;
      bottom: -200px;
      right: 0;

      border-radius: 50%;

      background-color: map-get($color-general, orange-two);
      opacity: 0.9;

      @include breakpoint(1100px) {
        display: block;
      }
    }
  }
}

@media (min-width: 1100px) {
  .s-hp-feature2 .c2 {
    float: right;
    width: 1108px;
    height: 520px;
    // background-color: transparent;
    // background-image: url(/images/bg_watch_them_grow.svg);
    // background-repeat: no-repeat;
    // background-position: top right;
    margin-top: -240px;
    box-shadow: none;
    margin-bottom: 0;
  }
}

.s-hp-feature2 .c2 .row div.ic {
  width: 120px;
  height: 120px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto;
  opacity: 0;
}

@media (min-width: 1100px) {
  .s-hp-feature2 .c2 .row div.ic {
    margin: 0;
  }
}

.s-hp-feature2 .c2 .row {
  font-size: 18px;
  line-height: 28px;
  padding: 60px 0;
  text-align: center;
  margin-left: 0;
  margin-right: 0;
}

@media (min-width: 992px) {
  .s-hp-feature2 .c2 .row {
    padding: 60px;
  }
}

@media (min-width: 1100px) {
  .s-hp-feature2 .c2 .row {
    width: 945px;
    // margin-top: 40px;
    // margin-left: 50px;
    text-align: left;
  }
}

.step-1,
.step-2,
.step-3,
.step-4,
.step-5 {
  span {
    opacity: 0;
  }
}

.s-hp-feature2 {
  &.animated {
    .c1 {
      &:before {
        animation-name: go-to-zero;
        animation-duration: .7s;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;

        will-change: transform;
      }

      h2 {
        animation-name: c-title;
        animation-duration: .7s;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;

        will-change: transform;
      }

      img {
        animation-name: c-img;
        animation-duration: .7s;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;

        will-change: transform;
      }
    }

    .c2 {
      .step-1 {
        .ic {
          @extend .opacity-left-slide-in;
          @extend .animation-delay-1;
        }

        span {
          @extend .opacity-left-slide-in;
          @extend .animation-delay-1-half;
        }
      }

      .step-2 {
        .ic {
          @extend .opacity-left-slide-in;
          @extend .animation-delay-1-half;
        }

        span {
          @extend .opacity-left-slide-in;
          @extend .animation-delay-2;
        }
      }

      .step-3 {
        .ic {
          @extend .opacity-left-slide-in;
          @extend .animation-delay-2;
        }

        span {
          @extend .opacity-left-slide-in;
          @extend .animation-delay-2-half;
        }
      }

      .step-4 {
        .ic {
          @extend .opacity-left-slide-in;
          @extend .animation-delay-2-half;
        }

        span {
          @extend .opacity-left-slide-in;
          @extend .animation-delay-3;
        }
      }

      .step-5 {
        .ic {
          @extend .opacity-left-slide-in;
          @extend .animation-delay-3;
        }

        span {
          @extend .opacity-left-slide-in;
          @extend .animation-delay-3-half;
        }
      }

      .row {
        &:after {
          animation-name: c-sun;
          animation-duration: 1.2s;
          animation-timing-function: ease-out;
          animation-fill-mode: forwards;
          animation-delay: 0.3s;
        }
      }
    }
  }
}

.s-hp-feature2-item-1-title .ic {
  height: 80px!important;
}

.s-hp-feature2-item-2-title .ic {
  height: 79px!important;
}

.s-hp-feature2-item-3-title .ic {
  height: 102px!important;
}


.s-hp-feature2-item-1-title .ic-title,
.s-hp-feature2-item-2-title .ic-title,
.s-hp-feature2-item-3-title .ic-title {
  font-size: 18px!important;
  line-height: 28px!important;
}

.s-hp-feature2-item-1-desc,
.s-hp-feature2-item-2-desc,
.s-hp-feature2-item-3-desc {
  font-size: 15px;
  line-height: 24px;
}

.s-hp-feature2-item-sep {
  height: 40px;
}
