.s-providers2 {
  background-color: map-get($color-general, white-two);
  padding-top: 80px;
  padding-bottom: 80px;
  background-image: url(/images/bg_icons_tuition_assistance.svg);
  background-position: bottom left;
  background-repeat: no-repeat;
}

.s-providers2 {
  background-image: url(/images/bg_icons_facility.svg);
  background-position: bottom right;
}

.s-providers2 h5 {
  padding-top: 20px;
  padding-bottom: 15px;
  font-size: rem(18);
}

.s-providers2 .sec-img img {
  height: 100px;
}

.s-providers2 div.desc {
  width: 100%;
  max-width: 260px;
  margin: 0 auto;
  font-weight: 100;
  font-size: 16px;
}
