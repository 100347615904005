.s-tuition-assistance {
  padding-top: 30px;
  padding-bottom: 60px;
}

@media (min-width: 992px) {
  .s-tuition-assistance {
    padding-top: 90px;
    padding-bottom: 100px;
  }
}

.s-tuition-assistance h2 {
  display: block;
  text-align: center;
  margin-bottom: 30px;
}

.s-tuition-assistance p.intro {
  font-size: 15px;
  line-height: 28px;
  font-weight: 100;
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
}

.s-why-use-fyv-tuition-assistance h2 {
  font-weight: 600;
  margin-left: 0;
  margin-top: 80px;
}

@media (min-width: 992px) {
  .s-why-use-fyv-tuition-assistance h2 {
    margin-left: 50px;
    margin-top: 160px;
  }
}

.s-why-use-fyv-tuition-assistance-cc {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 992px) {
  .s-why-use-fyv-tuition-assistance-cc {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.s-tuition-assistance-feature3 {
  background-color: map-get($color-general, white-two);
  padding-top: 80px;
  padding-bottom: 80px;
  background-image: url(/images/bg_icons_tuition_assistance.svg);
  background-position: bottom left;
  background-repeat: no-repeat;
}

.s-tuition-assistance-feature3 {
  position: relative;
  margin-top: -160px;
}


@media (min-width: 768px) {
  .s-tuition-assistance-feature3 {
    margin-top: -310px;
  }
}

@media (min-width: 992px) {
  .s-why-use-fyv-tuition-assistance h2 {
    margin-top: 100px;
  }

  .s-tuition-assistance-feature3 {
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .s-why-use-fyv-tuition-assistance h2 {
    margin-top: 150px;
  }

  .s-tuition-assistance-feature3 {
    margin-top: -230px;
  }
}

.s-tuition-assistance-feature3 h5 {
  padding-top: 20px;
  padding-bottom: 15px;
}

.s-tuition-assistance-feature3 .sec-img img {
  height: 100px;
}

.s-tuition-assistance-feature3 div.desc {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  font-weight: 100;
  font-size: 15px;
  line-height: 24px;
}

.s-tuition-assistance-feature-4 {
  position: relative;
  background-color: map-get($color-general, blue);
  padding-top: 80px;
  padding-bottom: 80px;
  background-image: url(/images/bg_get_started.svg);
  background-repeat: no-repeat;
  background-position: top left;
  color: $color-white;
}

@media (min-width: 992px) {
  .s-tuition-assistance-feature-4 {
    padding-top: 115px;
    padding-bottom: 115px;
  }
}

.s-tuition-assistance-feature-4 h2 {
  font-weight: 600;
  margin-bottom: 0;
}

.s-tuition-assistance-feature-4 a > img {
  height: 60px;
}

.c-books-and-pot {
  position: absolute;
  right: 0;
  height: 175px;
  bottom: -10px;
}

@media (min-width: 992px) {
  .c-books-and-pot {
    right: 30px;
  }
}
