.s-about {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (min-width: 992px) {
  .s-about {
    padding-top: 90px;
    padding-bottom: 100px;
  }
}

.s-about h2 {
  display: block;
  text-align: center;
  margin-bottom: 30px;
}

.s-about p.intro {
  font-size: 16px;
  font-weight: 100;
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
}

.s-about-feature1,
.s-about-feature2 {
  line-height: 24px;
  margin-bottom: 30px;

  @include breakpoint($md) {
    line-height: 24px;
    margin-bottom: 150px;
  }

  p {
    font-size: rem(12);
  }
}

.s-about-team {
  background-color: map-get($color-general, white-two);
  padding-top: 60px;
  padding-bottom: 70px;
}

@media (min-width: 992px) {
  .s-about-team {
    background-color: map-get($color-general, white-two);
    padding-top: 100px;
  }
}

.s-about-team-cw1,
.s-about-team-cw2 {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
  overflow-y: auto;
}

.s-about-team-cw1 .row {
  min-width: 860px;
}

.s-about-team-cw2 .row {
  min-width: 1000px;
}

@media (min-width: 992px) {
  .s-about-team-cw1,
  .s-about-team-cw2 {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .s-about-team-cw1 .row,
  .s-about-team-cw2 .row {
    min-width: 0px;
  }
}

.s-about-team > .row {
  margin-bottom: 0;
}

.s-about-team h2 {
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .s-about-team h2 {
    margin-bottom: 40px;
    text-align: center;
  }
}

.s-about-team .card {
  border: none;
  border-radius: 0;
  text-align: center;
}
