body,
html {
  height: 100%;
  min-height: 100%;
}

body {
  display: flex;
  min-height: 100vh;

  flex-direction: column;

  background-color: $color-white;

  font-family: "Axiforma", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  font-size: 14px;
  color: map-get($color-general, dark-blue);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
}

a,
a:hover,
a:focus,
a:visited,
a:active {
  color: map-get($color-general, dark-blue);
}

h1 {
  font-size: 60px;
  line-height: 70px;
}

h2 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 500;
}

h2 {
  font-size: 30px;
  line-height: 42px;
}

@media (min-width: 992px) {
  h2 {
    font-size: 40px;
    line-height: 55px;
  }
}

/* Change the color whote to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $color-white inset !important;
  box-shadow: 0 0 0 30px $color-white inset !important;
}

.text-primary {
  color: map-get($color-general, orange) !important;
}

