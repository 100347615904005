
.footer {
  background-color: map-get($color-general, white-two);
  padding-top: 40px;
  padding-bottom: 40px;
}

.footer-brand img {
  height: 70px;
}

.footer-nav {
  line-height: 70px;
  text-align: center;
}

.footer-nav a {
  display: inline-block;
  line-height: 70px;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 992px) {
  .footer-nav a {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.footer-social {
  text-align: right;
}

.footer-social a {
  text-align: right;
  margin-left: 30px;
  display: inline-block;
  margin-top: 22px;
}

.footer-social a:first-child {
  margin-left: 0;
}

.footer-social a img {
  height: 20px;
}

.made-in {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 20px;

  img {
    width: 18px;
    height: 23px;

    vertical-align: text-bottom;
  }
}

.copyright {
  font-size: 12px;
  font-weight: 100;
  text-align: center;
}
