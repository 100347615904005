.form-group-fl {
  position: relative;
  padding-top: 10px;
}

.form-group-fl input:focus ~ .floating-label,
.form-group-fl input:not(:focus):valid ~ .floating-label,
.form-group-fl textarea:focus ~ .floating-label,
.form-group-fl textarea:not(:focus):valid ~ .floating-label {
  top: 0px;
  left: 0;
  font-size: 10px;
  font-weight: 500;
}

.form-group-fl .form-control {
  font-size: 14px;
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
  box-shadow: none !important;
  padding-left: 0;
  padding-right: 0;
}

.form-group-fl .floating-label {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 17px;
  transition: 0.2s ease all;
  font-weight: 100;
  margin-bottom: 0;
}

.custom-select {
  border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: none!important;
  font-weight: 100;
  border-radius: 0;
  font-size: 14px;
  padding-left: 0;
}

.custom-select.text-white {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23ffffff' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
}

.custom-select option {
  color: #000!important;
}

.label-uppercase {
  font-size: 11px;
  font-weight: 800;
  line-height: 15px;
  display: block;
  text-transform: uppercase;
}

.custom-textbox {
  font-size: 14px;
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
  box-shadow: none !important;
  padding-left: 0;
  padding-right: 0;
  font-weight: 100;
}
