.s-hp-intro h1 {
  span {
    opacity: 0;
  }
}

.s-hp-intro .text-intro {
  font-size: 30px;
  line-height: 40px;
  font-weight: 100;

  opacity: 0;

  @extend .opacity-left-slide-in;
  @extend .animation-delay-2;
}

.s-hp-intro .text-price-old {
  position: relative;
  font-weight: 600;
  font-size: 48px;
  line-height: 74px;

  color: #979797;

  &:after {
    position: absolute;
    left: 0;
    right: 0;
    top: 36px;
    content: " ";
    background: #979797;
    height: 3px;
  }
}

.s-hp-intro .text-price-new {
  font-weight: 600;
  font-size: 48px;
  line-height: 74px;

  color: #91C524;
}

.s-hp-intro .text-price-period {
  font-weight: 200;
  font-size: 14px;
}

@media (max-width: $lg) {
  .s-hp-intro h1 br,
  .s-hp-intro .text-intro br, {
    display: none;
  }

  .s-hp-intro .text-arrow {
    width: 100%;
    padding-left: 90px;

    img {
      transform: rotate(90deg);
    }
  }
}

.s-hp-intro .text-footnote {
  font-weight: 200;
  font-size: 12px;
}

.s-hp-intro .store-apps a {
  display: inline-block;

  margin-right: 12px;
  opacity: 0;

  &:first-child {
    @extend .opacity-left-slide-in;
    @extend .animation-delay-4;
  }

  &:last-child {
    @extend .opacity-left-slide-in;
    @extend .animation-delay-3;
  }
}

.s-hp-intro .store-apps a:last-child {
  margin-right: 0;
}

.s-hp-intro .store-apps img {
  height: 40px;

  @include breakpoint(365px) {
    height: 50px;
  }
}

.s-hp-intro h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 48px;
}

.s-hp-intro-cover {
  max-width: 400px;
}

.s-hp-intro-cover {
  max-width: 400px;
}

.s-hp-intro .btn {
  max-width: 250px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 992px) {
  .s-hp-intro-cover {
    max-width: 100%;
  }

  .lazy-loading-top-video {
    left: 0;
    transform: translateX(0);
  }

  .s-hp-intro h1 {
    font-size: 60px;
    line-height: 70px;
  }
}

.s-hp-intro .btn {
  line-height: 21px;
}

// animations

.animated {
  h1 {
    span:first-child {
      @extend .opacity-left-slide-in;
    }

    span:last-child {
      @extend .opacity-left-slide-in;
      @extend .animation-delay-1;
    }
  }
}
