.recent-posts {
  padding: 30px 0;

  position: relative;
  z-index: 1;

  background-color: map-get($color-general, white-two);

  @include breakpoint($md) {
    padding: 50px 0;

    .blog-list__item {
      margin-bottom: 0;
    }
  }

  .blog-list {
    margin-bottom: 0;
  }

  .blog-list__item {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
