@import "~bootstrap";
@import "~flatpickr";

@import 'mixins';
@import 'variables';
@import 'general';
@import 'fonts';
@import 'utilities';

//@import 'components/header';
@import 'components/admin-header';
@import 'components/animations';
@import 'components/loading';
@import 'components/buttons';
@import 'components/navbar';
@import 'components/feature-list.scss';
@import 'components/facility-view.scss';
@import 'components/security';
@import 'components/intro-section';
@import 'components/budget-calculator';
@import 'components/search-facilities';
@import 'components/feature1-section.scss';
@import 'components/feature2-section.scss';
@import 'components/feature3-section.scss';
@import 'components/feature4-section.scss';
@import 'components/feature-how-fyv-works';
@import 'components/providers1-section.scss';
@import 'components/providers2-section.scss';
@import 'components/providers3-section.scss';
@import 'components/contact.scss';
@import 'components/about.scss';
@import 'components/apply';
@import 'components/apply-success';
@import 'components/profile-card.scss';
@import 'components/tuition-assistance.scss';
@import 'components/form.scss';
@import 'components/footer.scss';
@import 'components/blog.scss';
@import 'components/blog-single.scss';
@import 'components/recent-posts.scss';
@import 'components/cta-box.scss';
@import 'components/phone-overlay.scss';
@import 'components/lazy-loading.scss';
