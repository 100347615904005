.navbar-brand > img {
  height: 70px;
}

.navbar-light .navbar-nav .nav-link {
  color: map-get($color-general, dark-blue);
}

.navbar .btn-primary {
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0 5px 20px 0 #d8ddcd;
  line-height: 0;
}


.navbar-light .navbar-toggler {
  color: #000;
  border-color: transparent;
  outline: none !important;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.nav-item {
  position: relative;
}

.nav-item.active::after {
  content: "";
  display: block;
  position: absolute;
  height: 2px;
  bottom: 0;
  left: 50%;
  width: 40px;
  margin-left: -20px;
  background-color: map-get($color-general, orange);
}

@media (min-width: 992px) {
  .nav-item.active::after {
    left: 25px;
    right: 25px;
    width: auto;
    margin-left: 0;
  }
}
