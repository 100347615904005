.s-hp-feature1 {
  h2,
  h2 + p {
    opacity: 0;
  }

  &.animated {
    h2 {
      @extend .opacity-left-slide-in;

      & + p {
        @extend .opacity-left-slide-in;
        @extend .animation-delay-1;
      }
    }
  }
}

.s-hp-feature1 h5 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px;
}

.s-hp-feature1 h5 img {
  margin-right: 20px;
}

.s-hp-feature1-lc {
  padding-left: 0;
}

.s-hp-feature1-cc {
  padding-left: 0;
  padding-right: 0;

  // img {
  //   position: relative;

  //   opacity: 0;

  //   .animated & {
  //     @extend .opacity-bottom-slide-in;
  //   }
  // }
}

@media (min-width: 992px) {
  .s-hp-feature1-lc {
    padding-left: 15px;
  }

  .s-hp-feature1-cc {
    padding-left: 15px;
    padding-right: 15px;
  }
}
