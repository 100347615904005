.font-weight-100 {
  font-weight: 100;
}

.font-weight-200 {
  font-weight: 200;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-900 {
  font-weight: 900;
}

.text-secondary {
  color: map-get($color-general, blue-two) !important;
}

.s-bt {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (min-width: 992px) {
  .s-bt {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.s-bt h2 {
  display: block;
  text-align: center;
  margin-bottom: 0px;
}

.s-bt.refer h2 {
  font-weight: 700;
}

.s-bt h5 {
  display: block;
  text-align: center;
  margin-bottom: 0px;
  font-weight: 200;
  font-size: 18px;
  padding-top: 20px;
}

.s-btc {
  padding-bottom: 80px;
}

.refer-hero {
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.refer-intro {
  width: 100%;
  max-width: 380px;
  margin: 0 auto;
  text-align: center;
  padding: 50px 0;
  font-size: 16px;
  font-weight: 200;
}

.refer-intro strong.d-block {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 30px;
}

.refer-explained {
  background-color: #f6f8f9;
  padding: 60px 0;
}

.refer-explained > .container > .row h3 {
  font-weight: 700;
  margin-bottom: 25px;
}

.refer-explained > .container > .row p {
  margin-bottom: 25px;
  font-weight: 200;
  font-size: 16px;
}

@media (min-width: 992px) {
  .refer-hero {
    margin-top: -50px;
  }

  .refer-explained {
    padding: 120px 0;
  }

  .refer-explained > .container > .row p {
    margin-bottom: 0;
  }
}

.refer-explained > .container > h3 {
  font-weight: 700;
  text-align: center;
  margin-bottom: 50px;
}

#referral-code-wrapper,
#referral-form {
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
}

#referral-form .btn {
  display: block;
  width: 100%;
  max-width: 150px;
  margin: 0 auto;
}

#referral-code-wrapper {
  max-width: 350px;
  text-align: center;
}

#referral-code-wrapper h3 {
  font-weight: 700;
  font-size: 22px;
  margin-top: 30px;
}

#referral-code-wrapper div.alert {
  border-style: dashed;
  border-width: 2px;
  margin-top: 30px;
  background-color: #f1f8e4;
  border-color: #91c524;
  font-size: 16px;
  font-weight: 600;
}

#referral-code-wrapper p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 200;
}

.btn-clipboard {
  padding: 0 4px;
  margin: 0;
}

.btn-clipboard img {
  width: 14px;
}
