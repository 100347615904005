@font-face {
  font-family: Axiforma;
  font-weight: 100;
  src: url("/fonts/axiforma/axiforma-thin.otf") format("opentype");
}

@font-face {
  font-family: Axiforma;
  font-weight: 100;
  font-style: italic;
  src: url("/fonts/axiforma/axiforma-thin-italic.otf") format("opentype");
}

@font-face {
  font-family: Axiforma;
  font-weight: 200;
  src: url("/fonts/axiforma/axiforma-light.otf") format("opentype");
}

@font-face {
  font-family: Axiforma;
  font-weight: 200;
  font-style: italic;
  src: url("/fonts/axiforma/axiforma-light-italic.otf") format("opentype");
}

@font-face {
  font-family: Axiforma;
  font-weight: 300;
  src: url("/fonts/axiforma/axiforma-book.otf") format("opentype");
}

@font-face {
  font-family: Axiforma;
  font-weight: 300;
  font-style: italic;
  src: url("/fonts/axiforma/axiforma-book-italic.otf") format("opentype");
}

@font-face {
  font-family: Axiforma;
  font-weight: 400;
  src: url("/fonts/axiforma/axiforma-regular.otf") format("opentype");
}

@font-face {
  font-family: Axiforma;
  font-weight: 400;
  font-style: italic;
  src: url("/fonts/axiforma/axiforma-italic.otf") format("opentype");
}

@font-face {
  font-family: Axiforma;
  font-weight: 500;
  src: url("/fonts/axiforma/axiforma-medium.otf") format("opentype");
}

@font-face {
  font-family: Axiforma;
  font-weight: 500;
  font-style: italic;
  src: url("/fonts/axiforma/axiforma-medium-italic.otf") format("opentype");
}

@font-face {
  font-family: Axiforma;
  font-weight: 600;
  src: url("/fonts/axiforma/axiforma-semibold.otf") format("opentype");
}

@font-face {
  font-family: Axiforma;
  font-weight: 600;
  font-style: italic;
  src: url("/fonts/axiforma/axiforma-semibold-italic.otf") format("opentype");
}

@font-face {
  font-family: Axiforma;
  font-weight: 700;
  src: url("/fonts/axiforma/axiforma-bold.otf") format("opentype");
}

@font-face {
  font-family: Axiforma;
  font-weight: 700;
  font-style: italic;
  src: url("/fonts/axiforma/axiforma-bold-italic.otf") format("opentype");
}

@font-face {
  font-family: Axiforma;
  font-weight: 800;
  src: url("/fonts/axiforma/axiforma-extrabold.otf") format("opentype");
}

@font-face {
  font-family: Axiforma;
  font-weight: 800;
  font-style: italic;
  src: url("/fonts/axiforma/axiforma-extrabold-italic.otf") format("opentype");
}

@font-face {
  font-family: Axiforma;
  font-weight: 900;
  src: url("/fonts/axiforma/axiforma-black.otf") format("opentype");
}

@font-face {
  font-family: Axiforma;
  font-weight: 900;
  font-style: italic;
  src: url("/fonts/axiforma/axiforma-black-italic.otf") format("opentype");
}

@font-face {
  font-family: Axiforma;
  font-weight: 999;
  src: url("/fonts/axiforma/axiforma-heavy.otf") format("opentype");
}

@font-face {
  font-family: Axiforma;
  font-weight: 999;
  font-style: italic;
  src: url("/fonts/axiforma/axiforma-heavy-italic.otf") format("opentype");
}
