.profile-card .profile-img {
  width: 150px;
  height: 150px;
  border-radius: 150px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
}

.profile-card.profile-card-sm .profile-img {
  width: 120px;
  height: 120px;
  border-radius: 120px;
}

.profile-card .profile-name {
  display: block;
  font-weight: 500;
  font-size: 16px;
  padding-top: 10px;
  display: block;
}

.profile-card .profile-title {
  font-size: 12px;
  font-weight: 100;
  text-transform: uppercase;
  padding-top: 8px;
  display: block;
}

.profile-card .profile-social {
  padding-top: 20px;
  display: block;
}

.profile-card .profile-social a {
  padding-left: 10px;
  padding-right: 10px;
  display: inline-block;
}

.profile-card .profile-social a img {
  height: 16px;
}
