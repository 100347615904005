#budget-calculator-form h2 {
  max-width: 820px;
  margin: 0 auto;
  font-size: 40px;
  line-height: 58px;
  text-align: center;
  margin-bottom: 50px;
}

#budget-calculator-form .budget-calculator-card {
  max-width: 945px;
  margin: 0 auto;
  box-shadow: 0px 10px 50px rgba(4, 13, 35, 0.085637);
  padding: 50px 80px;
}

#budget-calculator-form #budget-calculator-submit {
  background: #91c524;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-weight: bold;
  display: block;
  text-align: center;
  width: 100%;
  max-width: 630px;
  margin: 0 auto;
  line-height: 26px;
  padding-top: 12px;
  padding-bottom: 12px;
}

#budget-calculator-form .custom-radio .custom-control-label {
  font-weight: 100;
}

#budget-calculator-card-result .budget-calculator-no-result {
  max-width: 630px;
  margin: 0 auto;
}

.budget-calculator-box {
  margin-bottom: 30px;
  background-color: #5aa0ff;
  color: #fff;
}

.budget-calculator-box .container-learn {
  background-image: url(/images/ic_learn.png);
  background-position: right center;
  background-size: 10%;
  background-repeat: no-repeat;
  height: 140px;
}

.budget-calculator-box h2 {
  font-size: 22px;
  line-height: 38px;
  font-weight: 700;
  margin-bottom: 0px;
}

@media (min-width: 576px) {
  .budget-calculator-box h2 {
    font-size: 40px;
    line-height: 58px;
  }
}

.budget-calculator-box h4 {
  font-size: 14px;
  line-height: 19px;
}

.budget-calculator-box small.ft {
  font-size: 14px;
  line-height: 19px;
  display: block;
}
