.facility-view-cover {
  background-size: cover;
  background-position: center center;
  max-width: 830px;
  height: 320px;
  margin: 0 auto 40px auto;
}

.facility-view-title {
  font-size: 36px;
  line-height: 50px;
}

.facility-view-description {
  font-size: 18px;
  line-height: 23px;
  font-weight: 100;
}

.facility-view-section-title {
  font-size: 18px;
  line-height: 24px;
  display: block;
  padding-top: 40px;
  padding-bottom: 10px;
}

.facility-view-partner {
  border: solid 1px #f0f0f0;
  padding: 16px 24px;
}

.facility-view-partner h4 {
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 200;
  margin-bottom: 16px;
}

.facility-view-partner h4 img {
  width: 24px;
  height: 24px;
  margin-left: 10px;
}

.facility-view-partner .item_licensing_number {
  display: block;
  background-image: url(/images/icon_winner.svg);
  background-repeat: no-repeat;
  line-height: 30px;
  padding-left: 40px;
  background-position: left center;
  font-weight: 100;
  margin-bottom: 10px;
}

.facility-view-partner .item_licensing_report {
  display: block;
  background-image: url(/images/icon_secure_shield.svg);
  background-repeat: no-repeat;
  line-height: 30px;
  padding-left: 40px;
  background-position: left center;
  font-weight: 100;
  text-decoration: underline;
}

.facility-view-partner .item_licensing_subsidy {
  display: block;
  background-image: url(/images/icon_gov.svg);
  background-repeat: no-repeat;
  line-height: 20px;
  padding-left: 40px;
  background-position: left center;
  font-weight: 100;
  margin-top: 10px;
}

.facility-view-partner .item_licensing_subsidy a {
  text-decoration: underline;
}

.facility-view-location {
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
  position: relative;
}

.facility-view-location::after {
  content: '';
  position: absolute;
  top: 53px;
  left: 50%;
  margin-left: -18px;
  width: 36px;
  height: 52px;
  background-image: url(/images/icon_pin.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.facility-view-location img {
  display: block;
  width: 210px;
  height: 210px;
  border-radius: 210px;
  margin: 0 auto;
}

.facility-view-location a {
  display: block;
  font-weight: 100;
  text-transform: uppercase;
  padding-top: 20px;
}

.facility-view-basics-tuition,
.facility-view-basics-subsidy,
.facility-view-basics-curriculum,
.facility-view-basics-ages,
.facility-view-basics-potty,
.facility-view-basics-meals,
.facility-view-basics-working-hours,
.facility-view-basics-working-days,
.facility-view-curriculum,
.facility-view-ages,
.facility-view-basics-snacks,
.facility-view-schedule-options,
.facility-view-basics-languages,
.facility-view-basics-gov {
  padding-left: 50px;
  background-position: 15px 2px;
  background-repeat: no-repeat;
}

.facility-view-basics-gov {
  background-image: url(/images/icon_gov.svg);
}

.facility-view-schedule-options {
  background-image: url(/images/icon_calendar_2.svg);
}

.facility-view-ages {
  background-image: url(/images/icon_cake.svg);
}

.facility-view-basics-snacks {
  background-image: url(/images/icon_snacks.svg);
}

.facility-view-curriculum {
  background-image: url(/images/icon_curriculum.svg);
}

.facility-view-basics-meals {
  background-image: url(/images/icon_meal.svg);
}

.facility-view-basics-tuition {
  background-image: url(/images/icon_coin.svg);
}

.facility-view-basics-subsidy {
  background-image: url(/images/icon_gov.svg);
}

.facility-view-basics-curriculum {
  background-image: url(/images/icon_curriculum.svg);
}

.facility-view-basics-ages {
  background-image: url(/images/icon_cake.svg);
}

.facility-view-basics-potty {
  background-image: url(/images/icon_mother.svg);
}

.facility-view-basics-working-hours {
  background-image: url(/images/icon_time.svg);
}

.facility-view-basics-working-days {
  background-image: url(/images/icon_calendar_1.svg);
}

.facility-view-basics-languages {
  background-image: url(/images/icon_languages.svg);
}

.facility-view-section-item {
  font-size: 18px;
  line-height: 30px;
  font-weight: 100;
  margin-bottom: 10px;
}
