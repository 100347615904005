.phone-overlay {
  position: relative;

  opacity: 0;

  .animated & {
    @extend .opacity-bottom-slide-in;
  }

  &__video {
    position: relative;
    z-index: 1;

    min-height: 500px;

    padding-left: 18%;
    padding-top: 16%;
    padding-right: 19%;

    @include breakpoint(350px) {
      padding-left: 16.1%;
      padding-right: 16.4%;
    }

    @include breakpoint(460px) {
      padding-left: 16.7%;
      padding-right: 16.7%;
    }

    @include breakpoint($sm) {
      padding-left: 17.3%;
      padding-right: 17.6%;
    }

    @include breakpoint($md) {
      padding-left: 16.5%;
      padding-top: 16.8%;
      padding-right: 16.8%;
    }

    video {
      width: 100%;
      max-width: none;
    }
  }

  &__bezel {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__shadow {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      width: 100%;
      height: auto;
    }
  }
}
