.s-hp-feature3-cc {
  padding-left: 0;
  padding-right: 0;

  img {
    opacity: 0;
  }
}

@media (min-width: 992px) {
  .s-hp-feature3-cc {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.s-hp-feature3 h2 {
  display: block;
  text-align: center;

  opacity: 0;
}

.s-hp-feature3 .c-more {
  text-align: center;
  padding-top: 40px;

  opacity: 0;
}

.s-hp-feature3 .c-more a {
  font-weight: 500;
  color: map-get($color-general, blue-two);
  font-size: 16px;
}

.s-hp-feature3 .c-more a img {
  margin-left: 10px;
}

.s-hp-feature3 .c-nlist:first-of-type {
  margin-top: 30px;
}

@media (min-width: 992px) {
  .s-hp-feature3 .c-nlist:first-of-type {
    margin-top: 170px;
  }
}

.s-hp-feature3 h5 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 15px;
}

.s-hp-feature3 h5 img {
  margin-right: 20px;
}

.s-hp-feature3 .c-nlist {
  background-size: 70px;
  background-position: -20px 42px;
}

.s-hp-feature3 .s-hp-feature3-lc {
  padding-left: 0;
}

@media (min-width: 992px) {
  .s-hp-feature3 .s-hp-feature3-lc {
    padding-left: 15px;
  }

  .s-hp-feature3 .c-nlist {
    background-size: 70px;
    background-position: 10px 42px;
  }
}

.s-hp-feature3 {
  @include breakpoint($md) {
    padding-top: 130px;
  }

  &.animated {
    h2 {
      @extend .opacity-left-slide-in;
    }

    .c-more {
      @extend .opacity-left-slide-in;
      @extend .animation-delay-1;
    }

    .s-hp-feature3-cc {
      img {
        @extend .opacity-bottom-slide-in;
      }
    }
  }
}
