.blog-post-group {
  max-width: 750px;

  margin: 0 auto;
  padding: 0 5px;

  @include breakpoint($sm) {
    padding: 0;
  }
}

.blog-post {
  &__title {
    margin-bottom: 20px;
    padding-top: 40px;

    font-size: 30px;
    font-weight: 600;
    line-height: 1.4;
    text-align: center;

    @include breakpoint($md) {
      margin-bottom: 30px;
      padding-top: 70px;

      font-size: 40px;
      line-height: 1.25;
    }
  }

  &__meta {
    display: flex;

    justify-content: center;
    text-transform: uppercase;

    padding: 0;

    list-style: none;

    li {
      font-size: 12px;
      font-weight: 200;
      line-height: 2;
      color: map-get($color-general, gray);

      &:first-child {
        padding-right: 30px;
      }
    }
  }

  &__image-group {
    width: 100%;

    padding-top: 56.27%;
    margin-bottom: 20px;

    position: relative;

    @include breakpoint($md) {
      margin-bottom: 30px;
    }
  }

  &__image {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    object-fit: cover;
  }

  &__content {
    h2 {
      font-size: 25px;
      line-height: 1.4;
    }

    h3 {
      font-size: 20px;
      line-height: 1.4;
    }

    p {
      margin-bottom: 30px;

      font-size: 16px;
      font-weight: 200;
      line-height: 1.88;
      color: map-get($color-general, gray);

      a {
        border-bottom: 1px solid map-get($color-general, green);

        transition: border .2s linear;

        &:hover {
          text-decoration: none;

          border-bottom: 1px solid map-get($color-general, orange);
        }
      }

      @include breakpoint($md) {
        margin-bottom: 40px;
      }
    }

    blockquote {
      margin-bottom: 30px;

      position: relative;

      font-size: 24px;
      line-height: 1.58;

      p {
        margin: 0;

        font-size: 24px;
        font-weight: 300;
        line-height: 1.58;

        &:before {
          display: inline-block;

          content: '\0201C';

          font-family: 'Axiforma', sans-serif;
          font-size: 40px;
          line-height: 1.25;
          font-weight: 600;

          position: absolute;
          left: -20px;
          top: 0;

          @include breakpoint($sm) {
            left: -30px;
          }
        }

        &:after {
          display: inline-block;

          content: '\0201D';

          font-family: 'Axiforma', sans-serif;
          font-size: 24px;
          line-height: 1.58;
        }
      }

      @include breakpoint($md) {
        margin-bottom: 40px;
      }
    }

    ul,
    ol {
      margin-bottom: 30px;
      margin-left: 25px;
      padding: 0;

      font-weight: 200;
      line-height: 1.88;


      @include breakpoint($md) {
        margin-bottom: 40px;
      }

      li {
        padding-left: 16px;
      }
    }

    hr {
      margin-bottom: 30px;

      border-color: map-get($color-general, gray);

      @include breakpoint($md) {
        margin-bottom: 40px;
      }
    }

    table {
      display: block;
      width: 100%;

      margin-bottom: 30px;

      // border-color: map-get($color-general, gray);
      border: none;

      color: map-get($color-general, gray);

      overflow-x: auto;
      -ms-overflow-style: -ms-autohiding-scrollbar;

      @include breakpoint($md) {
        display: table;

        margin-bottom: 40px;
      }

      th {
        padding: 9px 14px;

        font-weight: 500;
        line-height: 1.88;

        background-color: map-get($color-general, light-gray);
        border: none;

        &:not(:last-child) {
          border-right: 2px solid map-get($color-general, border);
        }
      }

      td {
        min-width: 248px;

        padding: 9px 14px;

        line-height: 1.88;
        font-weight: 200;

        border: none;

        &:not(:last-child) {
          border-right: 2px solid map-get($color-general, border);
        }

        @include breakpoint($md) {
          min-width: none;
        }
      }

      caption {
        padding: 0;
        margin-top: 20px;

        font-size: 12px;
        font-weight: 200;
        line-height: 2;
        text-align: center;

        border-top: 1px solid map-get($color-general, border);
      }
    }

    figure {
      margin-bottom: 20px;

      text-align: center;

      img {
        max-width: 100%;
        height: auto;

        margin-bottom: 5px;
      }

      figcaption {
        font-size: 12px;
        font-weight: 200;
        line-height: 2;
      }
    }
  }
}
