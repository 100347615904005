$size: 30;

.loading-spinner {
  color: map-get($color-general, gray);
  display: inline-block;
  position: relative;
  width: $size * 1px;
  height: $size * 1px;

  div {
    transform-origin: $size * 0.5px $size * 0.5px;
    animation: loading-spinner 1.2s linear infinite;

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      left: floor($size * 0.5px);
      width: floor($size * 0.08px);
      height: floor($size * 0.25px);
      border-radius: 20%;
      background: map-get($color-general, gray);
    }

    @for $i from 1 through 12 {
      $rotation: ($i - 1) * 30;
      $delay-diff: $i * 0.1;

      &:nth-child(#{$i}) {
        transform: rotate(0deg + $rotation);
        animation-delay: (-1.2s + $delay-diff);
      }
    }
  }
}

@keyframes loading-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
