#facility-search-results-map-wrapper {
  height: 10px;
  visibility: hidden;
}

.map-mobile-visible {
  height: 500px !important;
  visibility: visible !important;
}

.hide-mobile-list-view {
  display: none;
}

.view-toggle-wrapper {
  text-align: center;
  padding-bottom: 20px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 10px;
}

@media (min-width: 992px) {
  .view-toggle-wrapper {
    display: none;
  }

  .hide-mobile-list-view {
    display: block;
  }

  #facility-search-results-list {
    float: left;
    width: 40%;
    height: 900px !important;
    overflow-y: auto;
  }

  #facility-search-results-map-wrapper {
    display: block;
    float: left;
    width: 60%;
    height: 900px !important;
    margin-top: 0;
    visibility: visible !important;
  }
}

#facility-search-results-map {
  width: 100%;
  height: 100%;
}

#facility-search-results-list-items {
  padding-left: 16px;
  padding-right: 16px;
  font-weight: 100;
}

.facility-search-results-list-item {
  display: block;
  height: 190px;
  border: solid 1px #f0f0f0;
  text-decoration: none !important;
  position: relative;
  margin-bottom: 15px;
}

.facility-search-results-list-item .cover {
  background-color: #f4f4f4;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 190px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.facility-search-results-list-item .details {
  position: absolute;
  top: 15px;
  right: 15px;
  bottom: 15px;
  left: 205px;
}

.facility-search-results-list-item .details .details-title {
  display: block;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
}

.facility-search-results-list-item .details .details-location {
  display: block;
  font-size: 11px;
  line-height: 14px;
  margin-bottom: 12px;
}

.facility-search-results-list-item .details .details-desc {
  display: block;
  font-size: 11px;
  line-height: 14px;
}

.facility-search-results-list-item .details .details-footer {
  text-align: right;
  display: block;
  font-size: 11px;
  line-height: 14px;
  position: absolute;
  bottom: 0;
}

#facility-search-results-nav-prev {
  background-color: #91c524;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 16px;
  padding-left: 20px;
  padding-right: 20px;
  float: left;
  border: none;
  border-radius: 400px;
  margin-left: 15px;
}

#facility-search-results-nav-next {
  background-color: #91c524;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 16px;
  padding-left: 20px;
  padding-right: 20px;
  float: right;
  border: none;
  border-radius: 400px;
  margin-right: 15px;
}

#facility-search-button {
  background: #91c524;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-weight: bold;
  display: block;
  text-align: center;
  width: 100%;
  max-width: 630px;
  margin: 0 auto;
  line-height: 26px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.google-map-info-window {
  display: block;
  min-height: 180px;
  text-decoration: none!important;
  color: #232323!important;
}

.google-map-info-window .cover {
  width: 290px;
  height: 130px;
  background-color: #f0f0f0;
  display: block;
  margin-bottom: 10px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  margin-left: -12px;
  margin-top: -12px;
}

.google-map-info-window .title {
  display: block;
  font-size: 14px;
  line-height: 19px;
  font-weight: bold;
  width: 260px;
  padding-top: 128px;
}

.google-map-info-window .desc {
  display: block;
  font-size: 12px;
  line-height: 12px;
}

.waitlist-modal {
  padding: 16px;
}

.waitlist-modal h2 {
  display: block;
  font-size: 30px;
  line-height: 48px;
  font-weight: 100;
  margin-bottom: 10px;
}

.waitlist-modal h4 {
  display: block;
  font-size: 14px;
  line-height: 16px;
  font-weight: 100;
  margin-bottom: 100px;
}

.waitlist-modal a.more {
  font-size: 16px;
  line-height: 21px;
  display: block;
  text-align: center;
  font-weight: 100;
  padding-top: 100px;
}

@media (min-width: 992px) {
  .waitlist-modal {
    padding: 100px;
  }

  .waitlist-modal h2 {
    font-size: 60px;
    line-height: 78px;
  }

  .waitlist-modal h4 {
    font-size: 21px;
    line-height: 27px;
  }
}
