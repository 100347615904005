.c-nlist {
  padding-left: 15px;
  background-position: -20px 44px;
  background-repeat: no-repeat;
  font-size: 15px;
  font-weight: 100;
  line-height: 22px;
  margin-top: 30px;

  h5 {
    img,
    span {
      opacity: 0;
    }
  }

  p {
    position: relative;

    &:before {
      display: block;
      width: 80px;
      height: 70px;

      content: '';
      opacity: 1;

      position: absolute;
      left: -35px;

      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;

      @include breakpoint($md) {
        opacity: 0;

        left: 0;
      }
    }

    span {
      opacity: 0;
    }
  }

  .animated & {
    // first number
    &.c-nitem1 {
      img {
        @extend .opacity-right-slide-in;
        @extend .animation-delay-1-half;
      }

      span {
        @extend .opacity-left-slide-in;
        @extend .animation-delay-1-half;
      }

      p {
        span {
          @extend .opacity-left-slide-in;
          @extend .animation-delay-2;
        }
      }
    }

    // second number
    &.c-nitem2 {
      img {
        @extend .opacity-right-slide-in;
        @extend .animation-delay-2-half;
      }

      span {
        @extend .opacity-left-slide-in;
        @extend .animation-delay-2-half;
      }

      p {
        span {
          @extend .opacity-left-slide-in;
          @extend .animation-delay-3;
        }
      }
    }

    // third number
    &.c-nitem3 {
      img {
        @extend .opacity-right-slide-in;
        @extend .animation-delay-3-half;
      }

      span {
        @extend .opacity-left-slide-in;
        @extend .animation-delay-3-half;
      }

      p {
        span {
          @extend .opacity-left-slide-in;
          @extend .animation-delay-4;
        }
      }
    }
  }
}

.c-nlist:first-of-type {
  margin-top: 30px;
}

@media (min-width: 992px) {
  .c-nlist {
    padding-left: 80px;
    margin-top: 60px;
  }

  .c-nlist:first-of-type {
    margin-top: 150px;
  }
}

.c-nitem1 {
  p {
    &:before {
      background-image: url(/images/01.svg);

      @include breakpoint($md) {
        .animated & {

          animation-name: opacity-right-slide-in-number;
          animation-duration: .7s;
          animation-timing-function: ease-out;
          animation-fill-mode: forwards;
          animation-delay: 0.4s;
        }
      }
    }
  }
}

.c-nitem2 {
  p {
    &:before {
      background-image: url(/images/02.svg);

      @include breakpoint($md) {
        .animated & {

          animation-name: opacity-right-slide-in-number;
          animation-duration: .7s;
          animation-timing-function: ease-out;
          animation-fill-mode: forwards;
          animation-delay: 0.6s;
        }
      }
    }
  }
}

.c-nitem3 {
  p {
    &:before {
      background-image: url(/images/03.svg);

      @include breakpoint($md) {
        .animated & {

          animation-name: opacity-right-slide-in-number;
          animation-duration: .7s;
          animation-timing-function: ease-out;
          animation-fill-mode: forwards;
          animation-delay: 0.8s;
        }
      }
    }
  }
}

.c-nlist-m {
  padding-left: 0px;
  margin-top: 40px !important;
}

@media (min-width: 992px) {
  .c-nlist-m {
    padding-left: 50px;
    margin-top: 50px !important;
  }
}

.c-nlist-m h6 img {
  margin-right: 10px;
}

.c-nlist-m h6 img {
  display: block;
  padding-bottom: 20px;
}

@media (min-width: 992px) {
  .c-nlist-m h6 img {
    display: inline;
    padding-bottom: 0;
  }
}
