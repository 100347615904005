.s-apply-success {
  padding-top: rem(100);
  padding-bottom: rem(150);

  p.intro {
    color: map-get($color-general, gray);
    font-size: 30px;
    font-weight: 300;
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
    text-align: center;
  }

  img {
    margin-top: rem(75);
  }
}
