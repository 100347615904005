.s-contact {
  position: relative;
  z-index: 1;

  padding-top: 100px;
  padding-bottom: 60px;

  .contact-form {
    opacity: 0;
  }

  &.animated {
    h2 {
      span {
        display: block;

        &:first-child {
          @extend .opacity-left-slide-in;
        }

        &:last-child {
          @extend .opacity-left-slide-in;
          @extend .animation-delay-1;
        }
      }
    }

    .contact-intro,
    .contact-form {
      @extend .fade-in;
      @extend .animation-delay-1-half;
    }
  }
}

.s-contact .contact-hero {
  width: 100%;
  max-width: 240px;
}

@media (min-width: 992px) {
  .s-contact .contact-hero {
    max-width: 450px;
  }
}

.s-contact h2 {
  margin-bottom: 35px;

  span {
    opacity: 0;
  }
}

.s-contact p.contact-intro {
  font-weight: 100;
  margin-bottom: 20px;

  opacity: 0;
}

.s-contact .btn-primary {
  height: 50px;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 20px;
}
