.s-hp-feature4 {
  padding-top: 100px;
  padding-bottom: 100px;
  color: $color-white;
  background-color: #030E29;
  // background-image: url(/images/bg_submit_facility.svg);
  // background-position: -100px center;
  // background-repeat: no-repeat;
  // background-size: cover;
  text-align: center;
  margin-top: -160px;
  position: relative;

  &:after {
    content: '';

    display: block;
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    background-color: map-get($color-general, orange);
  }


  .licence-group {
    position: relative;
    z-index: 1;

    &:before {
      content: '';

      display: block;
      width: 270px;
      height: 270px;

      position: absolute;
      left: 0;
      bottom: -140px;

      background-color: rgba(255, 255, 255, 0.1);

      border-radius: 50%;
      opacity: 0;

      @include breakpoint($md) {
        width: 400px;
        height: 400px;

        bottom: -200px;
      }

      @include breakpoint($lg) {
        width: 730px;
        height: 730px;

        bottom: -600px;


        // transform: translate(50%, 50%);
      }
    }
  }

  &.animated {
    &:after {
      animation-name: go-to-zero;
      animation-duration: .7s;
      animation-timing-function: ease-out;
      animation-fill-mode: forwards;
    }

    .licence-group {
      &:before {
        animation-name: licence-ball-small;
        animation-duration: 1s;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;

        @include breakpoint($md) {
          animation-name: licence-ball-medium;
        }

        @include breakpoint($lg) {
          animation-name: licence-ball-large;
        }
      }
    }

    h2 {
      @extend .opacity-left-slide-in;
    }

    p {
      @extend .opacity-left-slide-in;
      @extend .animation-delay-1;
    }

    .btn {
      @extend .opacity-right-slide-in;
      @extend .animation-delay-1-half;
    }
  }
}

@media (min-width: 992px) {
  .s-hp-feature4.on-homepage {
    margin-top: 70px;
  }
}

.s-hp-feature4 h2 {
  margin-bottom: 0;

  opacity: 0;
}

.s-hp-feature4 p {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 0;
  font-weight: 100;
}

.s-hp-feature4 .btn-primary {
  height: 50px;
  padding-left: 50px;
  padding-right: 50px;

  opacity: 0;
}
