.s-hp-feature-how-fyv-works {
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 150px;

  h2 {
    margin-top: 50px;
    margin-bottom: 140px;
    text-align: center;

    font-weight: 600;
    font-size: 46px;
    color: map-get($color-general, orange-two);
  }

  &__step {
    margin-bottom: 115px;

    .step-content-wrapper {
      display: inline-block;
    }

    &.is-right {
      @include breakpoint($sm) {
        text-align: right;
      }
    }
  }

  h3 {
    position: relative;
    max-width: 580px;
    margin-bottom: 12px;

    font-weight: normal;
    font-size: 24px;

    @include breakpoint($sm) {
      font-size: 32px;
    }

    &:before {
      z-index: -1;
      display: block;
      width: 110px;
      height: 74px;

      content: '';
      opacity: 1;

      position: absolute;
      top: -52px;
      left: -27px;

      background-repeat: no-repeat;
      background-position: center center;
    }

    &:after {
      display: none;
      width: 160px;
      height: 170px;

      content: '';
      opacity: 1;

      position: absolute;

      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;

      @include breakpoint($sm) {
        display: block;
      }
    }
  }

  p {
    max-width: 420px;

    font-weight: 200;
    font-size: 18px;
    line-height: 24px;

    text-align: left;
  }

  &__step1 h3:after {
    background-image: url(/images/ic_apply.png);

    top: -20px;
    right: -120px;
  }

  &__step2 h3:after {
    background-image: url(/images/ic_grow_revenue.png);

    top: -5px;
    left: -210px;
  }

  &__step3 h3:after {
    background-image: url(/images/ic_pay_down.png);

    top: 45px;
    right: -25px;
  }

  @for $step from 1 through 3 {
    &__step#{$step} {
      h3 {
        &:before {
          background-image: url(/images/0#{$step}.svg);
          background-size: 100%;

          @include breakpoint($md) {
            .animated & {
              //animation-name: opacity-right-slide-in-number;
              //animation-duration: .7s;
              //animation-timing-function: ease-out;
              //animation-fill-mode: forwards;
              //animation-delay: (0.2 + $step * 0.2)s;
            }
          }
        }
      }
    }
  }
}
