.s-providers3 .row {
  margin-left: 0;
  margin-right: 0;
}

.s-providers3-c1 {
  padding-left: 0;
  padding-right: 0;

  @include breakpoint($md) {
    margin-bottom: 100px;
  }

  div.card {
    border: none;
    border-radius: 0;
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;

    @include breakpoint($md) {
      padding-top: 70px;
      padding-bottom: 70px;
      padding-left: 130px;
      padding-right: 130px;
    }
  }

  h2 {
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;

    @include breakpoint($md) {
      text-align: left;
    }
  }

  p {
    line-height: 26px;
    font-weight: 100;
    font-size: rem(14);
    margin-bottom: 40px;

    text-align: center;

    @include breakpoint($md) {
      text-align: left;
    }
  }

  .row {
    font-size: 12px;
    font-weight: 100;
  }

  div.checks div {
    padding-left: rem(27);
    position: relative;

    img.check {
      position: absolute;
      left: 0;
      top: 3px;
    }
  }
}

.s-providers3-c2 {
  padding-left: 0;
  padding-right: 0;
  vertical-align: top;
}

.s-providers3-c2 div.bg-wrapper {
  background-image: url(/images/bg_prelaunch.svg);
  background-repeat: no-repeat;
  background-position: top right;
  background-color: map-get($color-general, blue);
  padding-top: 80px;
  padding-bottom: 80px;
  color: $color-white;
  text-align: center;
}

.s-providers3-c2 h3 {
  margin-bottom: 30px;
  line-height: 40px;
}

@media (min-width: 992px) {
  .s-providers3-c2 div.bg-wrapper {
    padding-top: 111px;
    padding-bottom: 111px;
  }

  .s-providers3-c2 h3 {
    font-size: 40px;
    line-height: 58px;
    margin-bottom: 0px;
  }
}

.s-providers3-c2 .btn-primary {
  height: 55px;
  padding-left: 50px;
  padding-right: 50px;
  line-height: 42px;
  background-color: map-get($color-general, orange) !important;
  border-color: map-get($color-general, orange) !important;
}
