.s-apply {
  padding-top: 40px;
  padding-bottom: 40px;
}

.s-apply h1 {
  color: map-get($color-general, gray);
  display: block;
  text-align: center;
  margin-bottom: 15px;
  font-size: 40px;
  font-weight: 600;
}

.s-apply p.intro {
  color: map-get($color-general, gray);
  font-size: 20px;
  font-weight: 300;
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
}

.s-apply.form-wrapper {
  background: url('/images/ill_about_1.png');
  background-repeat: no-repeat;
  background-size: 100%;
  padding-bottom: rem(80);
  background-position: bottom center;

  @include breakpoint($lg) {
    padding-bottom: rem(190);
    background-position: bottom -320px center;
  }
}

.apply-form-section {
  background: $color-white;
  box-shadow: 0 10px 50px rgba(4, 13, 35, 0.085637);
  padding: rem(40) 5%;
  margin-bottom: rem(60);
  color: map-get($color-general, gray-two);

  @include breakpoint($md) {
    padding-left: rem(40);
    padding-right: rem(40);

    width: 960px;
  }

  @include breakpoint($lg) {
    padding-left: rem(110);
    padding-right: rem(110);
  }

  h2 {
    font-weight: 200;
    font-size: rem(20);
  }

  .form-group.search {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  label {
    font-weight: 200;

    &.form-check-label {
      color: map-get($color-general, gray);
    }

    a {
      text-decoration: underline;
    }
  }

  .disabled .input-group-text {
    background: #e9ecef;
  }

  input, .input-group-text {
    background: $color-white;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid map-get($color-general, gray-three);
    border-radius: 0;
    box-shadow: none !important;
    font-size: rem(14);
  }

  .form-error {
    input, .input-group-text {
      border-bottom: 1px solid map-get($color-general, error);
    }

    label {
      color: map-get($color-general, error);
    }
  }

  .apply-search-selected {
    color: map-get($color-general, gray);

    h3 {
      font-weight: normal;
      font-size: rem(16);
      margin-bottom: rem(12);
    }

    p {
      font-weight: 100;
      font-size: rem(10);
      margin-bottom: rem(4);
    }

    a {
      font-weight: 100;
      font-size: rem(10);
      line-height: rem(3);
      color: map-get($color-general, blue-three);
      text-decoration: underline;
    }
  }
}

.flatpickr-calendar .flatpickr-months .flatpickr-month {
  height: 40px;
}

.autocomplete-suggestions {
  background: $color-white;
  box-shadow: 0 0 8px rgba(37, 40, 43, 0.12);
  border-radius: 8px;
  color: map-get($color-general, gray);
  padding: 3px 0;
  max-height: unset !important;
}

.autocomplete-suggestion {
  padding: 6px 16px;
  cursor: pointer;
}

.autocomplete-no-suggestion {
  padding: 6px 16px;
}

.autocomplete-selected {
  background: map-get($color-general, blue-three-transparent);
}

.autocomplete-suggestions strong {
  font-weight: inherit;
}

.autocomplete-suggestions-loading {
  padding: 6px 16px;

  display: flex;
  align-items: center;

  .loading-spinner {
    margin-right: 16px;
  }
}
