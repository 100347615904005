.title-group--blog {
  padding-top: 40px;
  padding-bottom: 80px;

  h1 {
    margin-bottom: 16px;

    font-family: 'Axiforma', sans-serif;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.4;
    color: map-get($color-general, dark-blue);
    text-align: center;
  }

  @include breakpoint($md) {
    padding-top: 70px;
    padding-bottom: 100px;

    h1 {
      font-size: 40px;
      font-weight: 600;
      line-height: 1.25;
    }
  }
}

.list-categories {
  display: flex;

  list-style: none;

  justify-content: center;

  padding: 0;
  margin: 0;

  li {
    &:not(:last-child) {
      margin-right: 32px;
    }

    a {
      font-size: 15px;
      line-height: 1.87;
      color: map-get($color-general, gray);

      opacity: 0.5;

      transition: opacity .2s linear;

      &.active {
        opacity: 1;
      }

      &:hover {
        opacity: 1;

        text-decoration: none;
      }
    }
  }
}

.blog-list-group {
  padding: 24px 0 30px;

  position: relative;
  z-index: 1;

  background-color: map-get($color-general, white-two);

  @include breakpoint($md) {
    padding: 60px 0 52px;
  }
}

.blog-list {
  display: flex;
  width: 100%;

  flex-wrap: wrap;

  list-style: none;
  padding: 0;

  &__image-group {
    width: 100%;

    position: relative;

    padding-top: 50%;
  }

  &__image {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    object-fit: cover;
  }

  &__item {
    width: 100%;

    margin-bottom: 24px;

    background-color: $color-white;
    box-shadow: 0 10px 20px 0 rgba(4, 13, 35, 0.09);

    transition: box-shadow .2s linear;

    @include breakpoint($md) {
      width: calc(100% / 3 - 20px);

      margin-bottom: 30px;

      &:nth-child(2),
      &:nth-child(5),
      &:nth-child(8) {
        margin: 0 30px 30px;
      }
    }

    a {
      display: block;
      width: 100%;
      height: 100%;

      &.has-icon {
        position: relative;
      }
    }

    &:hover {
      box-shadow: 0 10px 30px 0 rgba(4, 13, 35, 0.3);

      a {
        text-decoration: none;
      }

    }
  }

  &__content {
    padding: 24px 24px;

    &.has-icon {
      position: relative;
    }
  }

  &__date {
    margin-bottom: 10px;

    font-size: 12px;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 2;
    color: map-get($color-general, gray);
  }

  &__title {
    font-size: 18px;
    line-height: 1.56;
  }
}

.blog-icon {
  position: absolute;

  &--1,
  &--8 {
    display: block;

    right: 0;
    bottom: 0;

    background-size: cover;
  }

  &--2,
  &--3 {
    display: block;

    right: 0;
    top: 0;

    background-size: cover;
  }

  &--4,
  &--5 {
    display: block;

    left: 0;
    bottom: 0;

    background-size: cover;
  }

  &--6,
  &--7 {
    display: block;

    top: 0;
    left: 0;

    background-size: cover;
  }

  &--1 {
    width: 64px;
    height: 54px;

    background-image: url('/images/blog-icons/rnd-img-01@3x.png');
  }

  &--2 {
    width: 52px;
    height: 46px;

    background-image: url('/images/blog-icons/rnd-img-02@3x.png');
  }

  &--3 {
    width: 42px;
    height: 48px;

    background-image: url('/images/blog-icons/rnd-img-03@3x.png');
  }

  &--4 {
    width: 44px;
    height: 45px;

    background-image: url('/images/blog-icons/rnd-img-04@3x.png');
  }

  &--5 {
    width: 47px;
    height: 33px;

    background-image: url('/images/blog-icons/rnd-img-05@3x.png');
  }

  &--6 {
    width: 22px;
    height: 42px;

    background-image: url('/images/blog-icons/rnd-img-06@3x.png');
  }

  &--7 {
    width: 38px;
    height: 23px;

    background-image: url('/images/blog-icons/rnd-img-07@3x.png');
  }

  &--8 {
    width: 38px;
    height: 31px;

    background-image: url('/images/blog-icons/rnd-img-08@3x.png');
  }

  &--9 {
    display: block;
    width: 83px;
    height: 25px;

    left: 10px;
    bottom: 0;

    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('/images/blog-icons/rnd-img-09@3x.png');
  }
}

.pagination-group {
  display: flex;
  width: 100%;

  justify-content: center;

  .pagination {
    margin: 0;
    padding-top: 32px;

    @include breakpoint($md) {
      padding-top: 60px;
    }

    .page-item {
      &:not(:last-child) {
        margin-right: 4px;
      }

      &.active {
        border-bottom: 2px solid map-get($color-general, orange-three);
      }

      &.hide-on-mobile {
        display: none;

        @include breakpoint($sm) {
          display: list-item;
        }
      }

      &.show-on-mobile {
        display: list-item;

        @include breakpoint($sm) {
          display: none;
        }
      }

      .page-link {
        display: flex;
        width: 34px;
        height: 34px;

        padding: 0;
        align-items: center;
        justify-content: center;

        opacity: 0.5;

        font-size: 14px;
        line-height: 1.29;
        color: map-get($color-general, dark-blue);

        border: none;
        border-radius: 0;
        background-color: transparent;

        transition: opacity .2s linear;

        &--arrow {
          opacity: 1;
        }

        &:hover {
          background-color: transparent;

          opacity: 1;
        }

        &:focus {
          box-shadow: none;
        }
      }

      &.disabled {
        .page-link {
          opacity: 1;
        }
      }
    }
  }
}
