.cta-box-group {
  position: relative;
  z-index: 0;

  padding: 80px 0;

  background-color: map-get($color-general, blue);

  @include breakpoint($md) {
    padding: 120px 0;
  }

  .cta-box {
    position: relative;

    &:before {
      display: block;
      width: 308px;
      height: 308px;

      content: '';

      position: absolute;
      top: -234px; // half an el height + parent padding
      left: -154px;

      border-radius: 50%;

      background-color: $color-white;
      opacity: 0.2;

      @include breakpoint($md) {
        top: -274px; // half an el height + parent padding
        left: -235px;
      }
    }

    &:after {
      display: block;
      width: 133px;
      height: 173px;

      content: '';

      position: absolute;
      right: 0;
      bottom: -95px;

      background-image: url('/images/books_and_pot.png');
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100%;

      @include breakpoint($md) {
        right: -60px;
        bottom: -135px;
      }
    }

    &__title {
      h1 {
        margin: 0;
      }
    }

    &__buttons {
      position: relative;
      z-index: 3;

      @include breakpoint($md) {
        display: flex;

        align-items: center;
      }
    }
  }

  h1 {
    font-size: 28px;
    line-height: 1.43;
    color: $color-white;

    @include breakpoint($md) {
      font-size: 40px;
      line-height: 1.45;
    }
  }
}
