/*
  Font variables
*/
$font-main: 'Roboto', sans-serif;

/*
  Breakpoints
*/
$xlarge: 1805px;
$mlarge: 1480px;
$large: 1279px;
$desktop: 1110px;
$tablet: 768px;

/* Bootstrap breakpoints */
$sm: 768px;
$md: 992px;
$lg: 1200px;

/*
  Color and color maps
*/

$color-white: #ffffff;

// User login/password reset colors
$color-map-admin-user: (
green: #00ce81,
label:#555555,
link: #808080,
link-hover: #b23b3b
);

$color-general: (
white-two: #f6f8f9,
blue: #5aa0ff,
blue-two: #4c70f8,
blue-three: #007eff,
blue-three-transparent: #f5faff,
light-blue: #1c263e,
dark-blue: #030e29,
orange: #ffb400,
orange-two: #ffca4c,
orange-three: #ffb403,
light-gray: #f4f4f7,
gray: #353946,
gray-two: #939393,
gray-three: #b8c5d0,
green: #91c524,
border: #e7e7eb,
error: #b23b3b,
)
