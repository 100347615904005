.opacity-left-slide-in {
  position: relative;
  animation-name: opacity-left-slide-in;
  animation-duration: .7s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;

  will-change: transform;
}

.opacity-right-slide-in {
  position: relative;
  animation-name: opacity-right-slide-in;
  animation-duration: .7s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;

  will-change: transform;
}

.opacity-bottom-slide-in {
  position: relative;
  animation-name: opacity-bottom-slide-in;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;

  will-change: transform;
}

.fade-in {
  position: relative;
  animation-name: fade-in;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;

  will-change: opacity;
}

.animation-delay-1 {
  animation-delay: 0.2s;
}

.animation-delay-1-half {
  animation-delay: 0.3s;
}

.animation-delay-2 {
  animation-delay: 0.4s;
}

.animation-delay-2-half {
  animation-delay: 0.5s;
}

.animation-delay-3 {
  animation-delay: 0.6s;
}

.animation-delay-3-half {
  animation-delay: 0.7s;
}

.animation-delay-4 {
  animation-delay: 0.8s;
}

@keyframes opacity-left-slide-in {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes opacity-right-slide-in {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes opacity-right-slide-in-number {
  0% {
    opacity: 0;
    transform: translateX(0);
  }

  100% {
    opacity: 1;
    transform: translateX(-80px);
  }
}

@keyframes opacity-bottom-slide-in {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes go-to-zero {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes c-title {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes c-img {
  0% {
    transform: translate(-50px, -50px);
    opacity: 0;
  }

  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes c-sun {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(85px);
  }
}

@keyframes licence-ball-small {
  0% {
    transform: translateX(-600px);
  }

  100% {
    transform: translateX(-115px);
    opacity: 1;
  }
}

@keyframes licence-ball-medium {
  0% {
    transform: translateX(-600px);
  }

  100% {
    transform: translateX(-200px);
    opacity: 1;
  }
}

@keyframes licence-ball-large {
  0% {
    transform: translateX(-1000px);
  }

  100% {
    transform: translateX(-250px);
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
